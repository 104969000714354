.App {
  background-color: antiquewhite;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.phone{
  font-size: 15px;
  font-family: 'Times New Roman', Times, serif;
  display: flex;
  margin-right: 50px;
}
.pic{
  width:40px;
  height: 40px;
  margin-right: 20px;
}
.fax{
  font-family: 'Times New Roman', Times, serif;
  font-size: 15px;
  margin-right: 50px;
}
.first{
  display: flex;
}
.contact{
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
  display: flex;
  margin-right: 350px;
}
.pic2{width: 28px;
  height: 28px;
  margin-top: 20px;
  margin-right: 10px;
}
.research{font-family: 'Times New Roman', Times, serif;
  font-size: 15px;
}
.alumini{font-family: 'Times New Roman', Times, serif;
  font-size: 15px;
  margin-left: 50px;
}
.second{
  background-color: rgba(37, 121, 37, 0.76);
}