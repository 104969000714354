.clg{
    width:1355px;
    height:500px;
}
.cse{
    font-size: 45px;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
}
.class{
    width:900px;
    height:500px;
    margin-left: 250px;
}
