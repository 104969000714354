.heading{
    font-size: 25px;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
}
.one{
    text-align: center;
    display: flex;
  
    border-radius: 500px;
}
.two{
    font-size: 25px;
    font-family: 'Times New Roman', Times, serif;
    border-radius: 500px;
    background-color:  rgb(123, 182, 69);
    margin-right: 50px;
    width: 250px;
    margin-left: 100px;
}
.three{
    margin-right: 50px;
    font-size: 25px;
    font-family: 'Times New Roman', Times, serif;
    border-radius: 500px;
    background-color: rgb(123, 182, 69);
    width: 250px;
}
.four{
    margin-right: 50px;
    font-size: 25px;
    font-family: 'Times New Roman', Times, serif;
    border-radius: 500px;
    background-color:  rgb(123, 182, 69);
    width: 250px;
}
.five{
    margin-right: 50px;
    font-size: 25px;
    font-family: 'Times New Roman', Times, serif;
    border-radius: 500px;
    background-color:  rgb(123, 182, 69);
    width: 250px;
}