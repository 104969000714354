
.clgg{
    width:400px;
    margin-bottom: 5px;
    margin-right: 30px;
    margin-left: 20px;
}
.fourth{
    display: flex;
}
.clgg1{
    height:500px;
    margin-right: 20px;
    margin-top: 20px;
}
.f1{
    text-align: center;
    font-size: 30px;
    font-family: 'Times New Roman', Times, serif;
    color:rgb(84, 119, 15);
}
.clgg2{
    text-align: center;
    font-size: 40px;
    font-family: 'Times New Roman', Times, serif;
    color: black;
    margin-top:0px;
    margin-bottom:0px;
}
.clgg3{
    text-align: center;
    font-size: 25px;
    font-family: 'Times New Roman', Times, serif;
    color: black;
    margin-top:0px;
    margin-bottom:0px;
}