.events{
    text-align: center;
    font-size: 30px;
    font-family: 'Times New Roman', Times, serif;
}
.a{
    display: flex;
}
.work{
    width: 450px;
    height: 230px;
    margin-right: 20px;
}
.work:hover{
    scale: 1.05;
    transition: ease-in-out 0.5s;
}
.ab{
    font-size: 22px;
    font-family: 'Times New Roman', Times, serif;
    margin-left:20px ;
    margin-right: 20px;
    background-color: rgba(240, 214, 182, 0.986);
    padding: 20px;
}
.ba{
    font-size: 22px;
    font-family: 'Times New Roman', Times, serif;
    margin-left:20px ;
    margin-right: 20px;
    background-color:  rgba(240, 214, 182, 0.986);
    padding: 20px;
}
.Environment{
    width: 450px;
    height: 230px;
    margin-left: 20px;
}
.Environment:hover{
    scale: 1.05;
    transition: ease-in-out 0.5s;
}
.b{
    display: flex;
}
.ca{
    font-size: 22px;
    font-family: 'Times New Roman', Times, serif;
    margin-left:20px ;
    margin-right: 20px;
    background-color:  rgba(240, 214, 182, 0.986);
    padding: 20px;
}
.Kannada{
    width: 500px;
    height: 230px;
    margin-right: 20px;
}
.Kannada:hover{
    scale: 1.05;
    transition: ease-in-out 0.5s;
}
.c{
    display:flex;
}
.da{
    font-size: 22px;
    font-family: 'Times New Roman', Times, serif;
    margin-left:20px ;
    margin-right: 20px;
    background-color:  rgba(240, 214, 182, 0.986);
    padding: 20px;
}
.d{
    display: flex;
}
.Graduation{
    width: 500px;
    height: 230px;
    margin-left: 20px;
}
.Graduation:hover{
    scale: 1.05;
    transition: ease-in-out 0.5s;
}
.e{
    display: flex;
}
.ea{
    font-size: 22px;
    font-family: 'Times New Roman', Times, serif;
    margin-left:20px ;
    margin-right: 20px;
    background-color:  rgba(240, 214, 182, 0.986);
    padding: 20px;
}
.Secura{
    width: 540px;
    height: 230px;
    margin-right: 10px;
    margin-left: 20px;
}
.Secura:hover{
    scale: 1.05;
    transition: ease-in-out 0.5s;
}
.f{
    margin-left: 500px;
}
.f:hover{
    scale: 1.05;
    transition: ease-in-out 0.5s;
}